<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
        <path
            fill-rule="nonzero"
            d="M7 0c3.9846154 0 7 3.13432836 7 7 0 1.56953427-.5148425 3.0185058-1.3859487 4.1860767l5.7099321 5.7091622-1.4142136 1.4142136-5.7078883-5.7069251C10.033024 13.4800572 8.57903846 14 7 14c-3.87692308 0-7-3.1343284-7-7 0-3.86567164 3.12307692-7 7-7Zm0 2C4.29166667 2 2 4.2 2 7s2.1875 5 5 5c2.70833333 0 5-2.2 5-5S9.8125 2 7 2Z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
